import React from 'react';
import { Link } from 'react-router-dom';

function NavigationMenu() {
    return (
        <nav className="navbar navbar-expand-lg   ">
            <div className="collapse navbar-collapse justify-content-end container " id="navbarNav">
                <ul className="navbar-nav  ">
                    <li className="nav-item ">
                        <Link className="nav-link text-secondary" to="/" >Home <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-secondary" to="/profile/Search" >Search</Link>
                    </li>
                    <li className="nav-item">
                        <Link className= "text-secondary nav-link" to="/About" >About</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className="nav-link text-secondary" to="/Contact" >Contact</Link>
                    </li>
                 
                </ul>
            </div>
        </nav>
    );
}

export default NavigationMenu;

