import axios from 'axios';
import { API_URL } from '../../common/AppControl';

// Import your JSON files directly
import titleData from '../../dropdowns/title.json';
import professionData from '../../dropdowns/profession.json';
import bodyTypeData from '../../dropdowns/bodytype.json';
import complexionData from '../../dropdowns/complexion.json';
import countryData from '../../dropdowns/country.json';
import createdbyData from '../../dropdowns/createdby.json';
import educationData from '../../dropdowns/education.json';
import ethnicityData from '../../dropdowns/ethnicity.json';
import foodHabitData from '../../dropdowns/foodhabit.json';
import heightTypeData from '../../dropdowns/heighttype.json';
import martialStatusData from '../../dropdowns/martialstatus.json';
import motherTongueData from '../../dropdowns/mothertonque.json';
import rasiData from '../../dropdowns/rasi.json';
import religionData from '../../dropdowns/religion.json';
import sexData from '../../dropdowns/sex.json';
import starData from '../../dropdowns/star.json';
import weightTypeData from '../../dropdowns/weightType.json';

const URL = `${API_URL}profilecx`;

// Function to get headers based on whether the token is needed
const GetHeaders = (requiresAuth, token) => {
  return requiresAuth
    ? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    : { 'Content-Type': 'application/json' };
};

// Existing API functions remain the same
export const fetchProfilesCx = async (searchText, page, pageSize, token) => {
  console.log(URL);
  const response = await axios.get(URL, {
    params: {
      searchtext: searchText,
      page: page,
      pageSize: pageSize,
    },
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const fetchProfileByIdCx = async (id, token) => {
  const response = await axios.get(`${URL}/${id}`, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const createProfileCx = async (data, token) => {
  const response = await axios.post(URL, data, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const updateProfileCx = async (id, data, token) => {
  const response = await axios.put(`${URL}/${id}`, data, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

export const deleteProfileCx = async (id, token) => {
  const response = await axios.delete(`${URL}/${id}`, {
    headers: GetHeaders(true, token),
  });
  return response.data;
};

// Updated functions to return imported dropdown data
export const fetchTitleData = () => {
  return titleData;
};

export const fetchProfessionData = () => {
  return professionData;
};

export const fetchBodyTypeData = () => {
  return bodyTypeData;
};

export const fetchComplexionData = () => {
  return complexionData;
};

export const fetchCountryData = () => {
  return countryData;
};

export const fetchCreatedbyData = () => {
  return createdbyData;
};

export const fetchEducationData = () => {
  return educationData;
};

export const fetchEthnicityData = () => {
  return ethnicityData;
};

export const fetchFoodHabitData = () => {
  return foodHabitData;
};

export const fetchHeightTypeData = () => {
  return heightTypeData;
};

export const fetchMartialStatusData = () => {
  return martialStatusData;
};

export const fetchMotherTongueData = () => {
  return motherTongueData;
};

export const fetchRasiData = () => {
  return rasiData;
};

export const fetchReligionData = () => {
  return religionData;
};

export const fetchSexData = () => {
  return sexData;
};

export const fetchStarData = () => {
  return starData;
};

export const fetchWeightTypeData = () => {
  return weightTypeData;
};
